* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:focus {
  outline: -webkit-focus-ring-color auto 0;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  zoom: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flex-menu{
  max-width: 80% !important;
  font-size: 20px;
}
/*
.card-body{
  padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 5px;
}
.card-name{
text-overflow: ellipsis;
font-weight: bold;
}
.card-email{
text-overflow: ellipsis;
}*/

.label-align {
    display: flex;
    align-items: center;
    height: 100%;
}
.footer{
  position:fixed;
  bottom:0;
  left : 0;
  width:100%;
}
.logo{
  margin-left:5px;
  font-size:58px;
  margin-bottom:5px;
  display: none;
}
.tc-container {
  height: 100%;
}
.logoButtonStyle{
  height: 100%;
}
textarea{
  resize: none;
}

.react-tabs {
  display: flex;
  width: 100%;
  height: 100%;
}
.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 330px;
  border-right: 1px solid var(--separator-color);
  border-top: 1px solid var(--separator-color);
  padding: 18px 8px;
}

.tabName{
  display: inline;
}
.react-tabs__tab {
  list-style: none;
  cursor: pointer;
  color: var(--item-font-color);
  padding: 6px 6px 6px 36px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 20px;
}
@media screen and (min-width:100px) and (max-width: 700px)
{
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 30px;
    border-right: 1px solid var(--separator-color);
    border-top: 1px solid var(--separator-color);
    padding: 5px 8px;
  }
  .tabName{
    display: none;
  }
  .react-tabs__tab {
    padding: 3px;
  }
}

.react-tabs__tab--selected {
  font-weight: 600;
  color: var(--selected-item-font-color);
  background: var(--selected-item-background-color);
}

.react-tabs__tab--disabled:not(.react-tabs__tab--selected) {
  cursor: default;
  color: var(--disabled-item-font-color);
}

.react-tabs__tab-panel {
  display: none;
  flex-grow: 1;
  padding: 0 32px 16px;
}

.react-tabs__tab-panel--selected {
  display: flex;
  flex-direction: column;
}

.panel-content {
  text-align: center;
}

li {
  outline:none
}
 .file-types{
  display: none !important;
}
.dragAndDrop {
    padding: 12px;
    max-width: 100%;
    display: block;
    height: auto;
    border: 1px dashed var(--separator-color);
    text-align: center;
    border-radius: 8px;
}
.dragAndDropSlates {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 51.25%;
  position: relative;
}
.dragAndDrop:hover {
  cursor: pointer;
}
.dragAndDropDragging {
    background: black;
    opacity: 0.2;
}